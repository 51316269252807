<template>
  <div class="Tests">
    <span
      lang="ru"
      style="position: relative;
    display: block;
    padding: 20px;
    border-radius: 12px;
    background-color: crimson;
    width: 95%;
    max-width: 600px;
    margin: 0% auto;
    hyphens: auto;"
      >Если произвести архивацию компетенции - отменить автоматическую смену
      компетенции у задач будет невозможно!
    </span>

    <div class="Buttons" v-if="!ButtonsBlocked">
      <template v-if="JobsList.length == 0">
        <GradientButton
          ButtonText="Загрузить список задач"
          @click.native="getJobs"
        />
      </template>

      <template v-else>
        <GradientButton
          v-if="TransferToSection != -1"
          ButtonText="Сохранить и применить ко всем задачам"
          @click.native="checkCompetenceArchieve"
        />

        <GradientButton
          v-if="TransferFromSection != -1 && TransferToSection == -1"
          ButtonText="Исправить уровни задачам"
          @click.native="fixJobsLevels"
        />
      </template>
    </div>

    <div class="CompetenceTransfer">
      <div class="SectorsWithSections">
        <div class="Section">
          <span>Секция которую нужно заменить и архивировать</span>
          <select v-model="TransferFromSection">
            <option :value="-1" selected>Выберите секцию</option>
            <option
              v-for="(sect, sindx) in GetAllSections"
              :key="`${sindx}${sect.id}${sect.name}`"
              :value="sect.id"
              >{{ sect.name }}</option
            >
          </select>
        </div>

        <div class="Section">
          <span>Заменить на</span>
          <select v-model="TransferToSection">
            <option :value="-1" selected>Выберите секцию</option>

            <option
              v-for="(sect, sindx) in GetAllAvailableSection"
              :key="`${sindx}${sect.id}${sect.name}`"
              :value="sect.id"
              >{{ sect.name }}</option
            >
          </select>
        </div>
      </div>
    </div>

    <div class="Console">
      <template v-if="ConsoleCacheList.length == 0">
        <span style="width: 100%; text-align: center; color: #535353;"
          >Здесь пусто</span
        >
      </template>
      <template v-else>
        <span
          v-for="(text, tindx) in ConsoleCacheList"
          :key="`${text}-${tindx}`"
          >{{ text }}</span
        >
      </template>
    </div>

    <div class="ArchievedCompetence">
      <span
        lang="ru"
        style="position: relative;
    display: block;
    padding: 20px;
    border-radius: 12px;
    width: auto;
    max-width: 600px;
    margin: 0% auto;
    hyphens: auto;
    
    font-size: 20px;
    "
        >Архивные направления
      </span>

      <div
        class="block"
        v-for="(sect, sindx) in GetAllArchievedSections"
        :key="`${sindx}${sect.id}${sect.name}`"
      >
        <span>{{ sect.name }}</span>

        <GradientButton
          ButtonText="Убрать из архива"
          @click.native="returnBackFromArchieve(sect.id)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  getAllJobsChecker,
  postCheckCompetenceArchieve,
  changeArchieveCompetence,
} from "@/api/system_checker.js";

import {
  getSectorsWithSections,
  getSectorsWithSectionsArchieved,
} from "@/api/competence.js";

import { getSection } from "@/api/competence.js";

import GradientButton from "@/components/CustomElements/ButtonGradient.vue";

export default {
  name: "AdminCheckCoinsAndAchievements",
  components: { GradientButton },
  data() {
    return {
      ConsoleCacheList: [],
      JobsList: [],

      JobsPerTick: 50,
      TickSleep: 800,

      ButtonsBlocked: false,
      IterationNumber: 0,
      LastIteration: false,
      IterationData: {},

      TransferFromSection: -1,
      TransferToSection: -1,

      BlockCrossSectorTransfer: true,

      Sectors: [],
      SectionsArchieved: [],

      SectionFromLevels: [],
      SectionToLevels: [],
    };
  },
  async created() {
    await this.initialization();
  },
  watch: {},
  computed: {
    JobsListForSections() {
      return this.JobsList.filter(
        (x) => x.sectionId == this.TransferFromSection
      );
    },

    GetAllSections() {
      let sectionsNames = this.Sectors.map((sector) => {
        return sector.sections;
      }).reduce((acc, val) => acc.concat(val), []);

      return sectionsNames;
    },

    GetAllArchievedSections() {
      let sectionsNames = this.SectionsArchieved.map((sector) => {
        return sector.sections;
      }).reduce((acc, val) => acc.concat(val), []);

      return sectionsNames;
    },

    GetAllAvailableSection() {
      if (this.TransferFromSection == -1) {
        return [];
      }

      if (this.BlockCrossSectorTransfer) {
        let findSectorFromStartSection = this.Sectors.find((_sector) => {
          let _sector_sections = _sector.sections.map((_sect) => _sect.id);

          if (_sector_sections.includes(this.TransferFromSection)) {
            return _sector;
          }
        });

        return findSectorFromStartSection.sections.filter(
          (x) => x.id !== this.TransferFromSection
        );
      } else {
        return this.GetAllSections.filter(
          (x) => x.id !== this.TransferFromSection
        );
      }
    },
  },
  methods: {
    async initialization() {
      let _sectors_with_sections_resp = await getSectorsWithSections();
      this.Sectors = _sectors_with_sections_resp.data;

      let _sections_archieved_resp = await getSectorsWithSectionsArchieved();
      this.SectionsArchieved = _sections_archieved_resp.data;
    },

    async resetIteration() {
      this.ConsoleCacheList = [];
      this.IterationNumber = 0;
      this.IterationData = {};
      this.LastIteration = false;
    },

    async getJobs() {
      await this.resetIteration();

      this.ConsoleCacheList = [`[${this.getTimeNow()}] Получение задач...`];
      this.ButtonsBlocked = true;

      await this.getJobsData();
    },
    async getJobsData() {
      this.IterationNumber++;

      if (this.LastIteration) {
        this.ConsoleCacheList.unshift(
          `[${this.getTimeNow()}] Задач было загруженно: ${
            this.JobsList.length
          } `
        );
        this.ConsoleCacheList.unshift(
          `[${this.getTimeNow()}] Получение списка задач завершено`
        );

        this.JobsPerTick = 50;
        this.TickSleep = 1500;

        this.ButtonsBlocked = false;
        return 0;
      }

      this.ConsoleCacheList.unshift(
        `[${this.getTimeNow()}] Номер итерации: ${this.IterationNumber}`
      );

      let timeStart = new Date().getTime();
      let _users_list_resp = await getAllJobsChecker(
        this.IterationNumber,
        this.JobsPerTick
      );
      let timeEnd = new Date().getTime();
      let MeasureTime = timeEnd - timeStart;

      this.ConsoleCacheList.unshift(
        `[${this.getTimeNow()}] Получено ${
          _users_list_resp.data.length
        } задач (за ${MeasureTime} мс)`
      );
      if (_users_list_resp.data.length < this.JobsPerTick) {
        this.LastIteration = true;
      }

      this.JobsList = this.JobsList.concat(_users_list_resp.data);

      if (this.LastIteration) {
        await this.getJobsData();
      } else {
        setTimeout(async () => {
          await this.getJobsData();
        }, this.TickSleep);
      }
    },

    async checkCompetenceArchieve() {
      await this.resetIteration();

      this.SectionToLevels = await this.getLevelsFromSection(
        this.TransferToSection
      );

      this.ConsoleCacheList.unshift(
        `[${this.getTimeNow()}] Загружены уровни конечной секции`
      );

      this.ConsoleCacheList = [
        `[${this.getTimeNow()}] Запущен процесс смены компетенции для задач...`,
      ];
      this.ConsoleCacheList.unshift(
        `[${this.getTimeNow()}] Всего задач с нужной компетенцией: ${
          this.JobsListForSections.length
        }`
      );
      this.ButtonsBlocked = true;

      this.IterationData = {
        updatedUsersCount: 0,
      };

      await this.sendCompetenceArchieveData();
    },
    async sendCompetenceArchieveData() {
      this.IterationNumber++;

      if (this.LastIteration) {
        this.ConsoleCacheList.unshift(
          `[${this.getTimeNow()}] Задач обновленно: ${
            this.IterationData.updatedUsersCount
          } / ${this.JobsListForSections.length}`
        );

        this.ConsoleCacheList.unshift(
          `[${this.getTimeNow()}] Обновление задач завершено`
        );

        await changeArchieveCompetence(this.TransferFromSection, 1);

        this.ConsoleCacheList.unshift(
          `[${this.getTimeNow()}] Направление архивировано.`
        );

        await this.initialization();

        this.ButtonsBlocked = false;
        return 0;
      }

      if (
        this.JobsPerTick * this.IterationNumber >=
        this.JobsListForSections.length
      ) {
        this.LastIteration = true;
        this.ConsoleCacheList.unshift(
          `[${this.getTimeNow()}] Номер итерации: ${
            this.IterationNumber
          } (Последний)`
        );
      } else {
        this.ConsoleCacheList.unshift(
          `[${this.getTimeNow()}] Номер итерации: ${this.IterationNumber}`
        );
      }

      let jobs_piece = [
        ...this.JobsListForSections.slice(
          this.JobsPerTick * (this.IterationNumber - 1),
          this.JobsPerTick * this.IterationNumber
        ),
      ];

      let req_data = {
        jobs: [...jobs_piece],
        setTo: this.TransferToSection,
        levels: this.SectionToLevels.map((x) => x.level_id),
      };

      let timeStart = new Date().getTime();
      let _achievements_resp = await postCheckCompetenceArchieve(req_data);
      let timeEnd = new Date().getTime();

      let MeasureTime = timeEnd - timeStart;

      this.ConsoleCacheList.unshift(
        `Обновлено: ${_achievements_resp.data.updated}; Ошибка в обновлении: ${_achievements_resp.data.errors}; (${MeasureTime} мс)`
      );

      console.log("Dev data");
      console.log(_achievements_resp.data);

      this.IterationData.updatedUsersCount += _achievements_resp.data.updated;

      if (this.LastIteration) {
        await this.sendCompetenceArchieveData();
      } else {
        setTimeout(async () => {
          await this.sendCompetenceArchieveData();
        }, this.TickSleep);
      }
    },

    async fixJobsLevels() {
      await this.resetIteration();

      this.SectionFromLevels = await this.getLevelsFromSection(
        this.TransferFromSection
      );

      this.ConsoleCacheList.unshift(
        `[${this.getTimeNow()}] Загружены уровни начальной секции`
      );

      this.ConsoleCacheList = [
        `[${this.getTimeNow()}] Запущен процесс смены компетенции для задач...`,
      ];
      this.ConsoleCacheList.unshift(
        `[${this.getTimeNow()}] Всего задач с нужной компетенцией: ${
          this.JobsListForSections.length
        }`
      );
      this.ButtonsBlocked = true;

      this.IterationData = {
        updatedUsersCount: 0,
      };

      await this.sendJobsLevelsData();
    },
    async sendJobsLevelsData() {
      this.IterationNumber++;

      if (this.LastIteration) {
        this.ConsoleCacheList.unshift(
          `[${this.getTimeNow()}] Задач обновленно: ${
            this.IterationData.updatedUsersCount
          } / ${this.JobsListForSections.length}`
        );

        this.ConsoleCacheList.unshift(
          `[${this.getTimeNow()}] Обновление задач завершено`
        );

        await this.initialization();

        this.ButtonsBlocked = false;
        return 0;
      }

      if (
        this.JobsPerTick * this.IterationNumber >=
        this.JobsListForSections.length
      ) {
        this.LastIteration = true;
        this.ConsoleCacheList.unshift(
          `[${this.getTimeNow()}] Номер итерации: ${
            this.IterationNumber
          } (Последний)`
        );
      } else {
        this.ConsoleCacheList.unshift(
          `[${this.getTimeNow()}] Номер итерации: ${this.IterationNumber}`
        );
      }

      let jobs_piece = [
        ...this.JobsListForSections.slice(
          this.JobsPerTick * (this.IterationNumber - 1),
          this.JobsPerTick * this.IterationNumber
        ),
      ];

      let req_data = {
        jobs: [...jobs_piece],
        setTo: this.TransferFromSection,
        levels: this.SectionFromLevels.map((x) => x.level_id),
      };

      let timeStart = new Date().getTime();
      let _achievements_resp = await postCheckCompetenceArchieve(req_data);
      let timeEnd = new Date().getTime();

      let MeasureTime = timeEnd - timeStart;

      this.ConsoleCacheList.unshift(
        `Обновлено: ${_achievements_resp.data.updated}; Ошибка в обновлении: ${_achievements_resp.data.errors}; (${MeasureTime} мс)`
      );

      console.log("Dev data");
      console.log(_achievements_resp.data);

      this.IterationData.updatedUsersCount += _achievements_resp.data.updated;

      if (this.LastIteration) {
        await this.sendJobsLevelsData();
      } else {
        setTimeout(async () => {
          await this.sendJobsLevelsData();
        }, this.TickSleep);
      }
    },

    async getLevelsFromSection(section) {
      if (section == -1) {
        return [];
      }
      let LevelsFromSection_resp = await getSection(section);
      let LEVELS = [];
      let countL = 1;
      LevelsFromSection_resp.data.degrees.sort((a, b) => b.id - a.id);
      for (let i = 0; i < LevelsFromSection_resp.data.degrees.length; i++) {
        LevelsFromSection_resp.data.degrees[i].levels.sort(
          (a, b) => a.id - b.id
        );
        for (
          let j = 0;
          j < LevelsFromSection_resp.data.degrees[i].levels.length;
          j++
        ) {
          LEVELS.push(
            Object.assign(LevelsFromSection_resp.data.degrees[i].levels[j], {
              level: countL,
            })
          );
          countL++;
        }
      }
      return LEVELS.map((x, indx) => {
        return {
          max_level: indx + 1,
          level_id: x.id,
        };
      });
    },

    getTimeNow() {
      var currentdate = new Date();
      var datetime =
        currentdate.getHours() +
        ":" +
        currentdate.getMinutes() +
        ":" +
        currentdate.getSeconds();

      return datetime;
    },

    async returnBackFromArchieve(Section_ID) {
      let a = await changeArchieveCompetence(Section_ID, 0);
      await this.initialization();
    },
  },
};
</script>

<style scoped>
.ArchievedCompetence {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 15px;

  width: fit-content;
  height: fit-content;

  margin: 0% auto;
}
.ArchievedCompetence > span {
  margin-bottom: 30px;
}
.ArchievedCompetence > .block > * {
  margin-top: auto;
  margin-bottom: auto;
}
.ArchievedCompetence > .block {
  position: relative;
  display: flex;

  flex-direction: row;
  justify-content: flex-start;
  gap: 30px;

  width: fit-content;
  height: fit-content;

  margin: 0% auto;

  padding: 20px;
  border-radius: 12px;
  background-color: #6152b940;
}
.ArchievedCompetence > .block > span {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  font-size: 20px;
}

.CompetenceTransfer {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 30px;

  width: fit-content;
  height: fit-content;

  margin: 0% auto;
}
.CompetenceTransfer > .SectorsWithSections {
  position: relative;
  display: flex;

  flex-direction: row;
  justify-content: flex-start;
  gap: 60px;

  height: fit-content;
  width: fit-content;

  margin: 0% auto;
}
.CompetenceTransfer > .SectorsWithSections > .Section {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 30px;

  width: 300px;
  height: auto;

  flex-basis: auto;
}
.CompetenceTransfer > .SectorsWithSections > .Section > span {
  margin-bottom: auto;
  text-align: left;
}
.CompetenceTransfer > .SectorsWithSections > .Section > input {
  position: relative;
  display: block;

  width: 100%;
  height: fit-content;
}

.TransformTemplate {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  margin: 0% auto;
  gap: 10px;

  width: fit-content;
  height: fit-content;
}
.TransformTemplate > .TemplateRule {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 30px;

  width: fit-content;
  height: fit-content;
}
.TransformTemplate > .TemplateRule > input {
  width: 450px;
  text-align: center;
}
.TransformTemplate > .TemplateRule > span {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  font-size: 24px;
  line-height: normal;
  font-weight: 600;
}

.Advanced {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;

  margin: 0% auto;

  width: fit-content;
  height: fit-content;
}
.Advanced > summary {
  position: relative;
  display: block;

  margin: 0% auto;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 18px;
  font-weight: 600;
}

/* Убираем стандартный маркер Chrome */
.Advanced > summary::-webkit-details-marker {
  display: none;
}
/* Убираем стандартный маркер Firefox */
.Advanced > summary {
  list-style: none;
}

/* Добавляем собственный маркер для закрытого состояния */
.Advanced > summary:before {
  content: "\21A0";
  margin-right: 7px;
}

/* Добавляем собственный маркер для открытого состояния */
.Advanced[open] > summary:before {
  content: "\21A1";
}

.Tests {
  position: relative;

  padding-top: 60px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 60px;

  padding: 50px 8vw;

  width: 100%;
  min-height: 100vh;

  color: white;
}
.Buttons {
  position: relative;
  display: flex;

  padding: 30px 0%;

  flex-direction: row;
  justify-content: flex-start;
  gap: 30px;

  width: fit-content;
  height: fit-content;

  margin: 0% auto;
}

.Buttons > .Block {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;

  width: fit-content;
  height: auto;
  flex-basis: auto;
}
.Buttons > .Block > label {
  position: relative;
  display: block;

  width: 300px;
  height: fit-content;
  text-align: left;

  margin-bottom: auto;
}

.Console {
  position: relative;
  display: flex;

  margin: 0% auto;

  padding: 60px 10px 10px 10px;

  width: 500px;
  height: 500px;

  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;

  background-color: #1a1a1a;
  overflow-y: auto;
  border-radius: 12px;
}

.Console > span {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  text-align: left;
}

.Console::after {
  content: "Console";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #464646;
  text-align: left;
  padding: 10px;
}
</style>
