import request from "@/api/config.js";

export function getAllUsersChecker(tick, user_count) {
  return request({
    url: `system_checker/get/all-users/${tick}/${user_count}`,
    method: "get",
  });
}

export function getAllJobsChecker(tick, jobs_count) {
  return request({
    url: `system_checker/get/all-jobs/${tick}/${jobs_count}`,
    method: "get",
  });
}

export function postCheckUsersAchievements(data) {
  return request({
    url: `system_checker/post/check/achievements`,
    method: "post",
    data,
  });
}

export function postCheckCompetenceArchieve(data) {
  return request({
    url: `system_checker/post/check/competence-archieve`,
    method: "post",
    data,
  });
}

export function postCheckUsersCoins(data) {
  return request({
    url: `system_checker/post/check/coins`,
    method: "post",
    data,
  });
}

export function postTransferCompetence(data) {
  return request({
    url: `system_checker/post/transfer/competence`,
    method: "post",
    data,
  });
}

export function postDeclineTransferCompetence(data) {
  return request({
    url: `system_checker/post/decline-transfer/competence`,
    method: "post",
    data,
  });
}

export function changeArchieveCompetence(sect_id, set_val) {
  return request({
    url: `system_checker/post/section/${sect_id}/${set_val}`,
    method: "post",
  });
}
